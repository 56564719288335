import $ from 'jquery';

import { SETTINGS } from '../commons/settings';
import { UTILS } from '../utils/utils';
import { PAGES } from '../partials/pages';
import { SEARCH } from './search';

const LISTPROPIERTIES = {
  listProperties(properties, typologyName) {
    let arrayProperties = [];
    let offset = true;
    properties.data.forEach((item) => {
      offset = !offset;
      let offsetClass = (offset === true) ? 'offset-lg-1' : '';
      let cardPropertyMarkup = `
        <div class="col-lg-5 ${offsetClass}">
          <div class="item-property">
            <bdi class="code-rif w-bold">Rif. ${item.attributes.reference}</bdi>
            <figure class="img-property" style="background-image: url(${SETTINGS.baseUrlBackend}${item.attributes.cover.data.attributes.url});"></figure>
            <hgroup class="caption">
              <h4 class="w-bold cl-institutional">${item.attributes.typology.data.attributes.name}</h4>
              <h5 class="mt-1 w-bold">${item.attributes.category.data.attributes.name}</h5>
              <h6 class="mt-1">
                <span class="zone">${item.attributes.place}</span>
                <span class="separator">/</span>
                <span class="city">${item.attributes.city.data.attributes.name}</span>
              </h6>
              <a href="${SETTINGS.baseUrl}/dettaglio-immobile?id=${item.id}" class="read-more">
                read more
              </a>
            </hgroup>
          </div>
        </div>
      `;
      arrayProperties.push(cardPropertyMarkup);
    });
    $(`.${typologyName} .list-properties .cards-properties`).html(arrayProperties);

    this.pagination(properties.meta, typologyName);
    this.countNumber(typologyName, properties.meta.pagination.total);
  },
  pagination(meta, typologyName) {
    const locationOrigin = window.location.origin; // .slice(0, -1)
    let locationPathname = window.location.pathname.replace(/\//g, '');
    let currentPage = `${locationOrigin}/${locationPathname}`;
    let pageCount = [];
    for (let i = 1; i < meta.pagination.pageCount + 1; i++) {
      pageCount.push(i);
    }

    let paginationNav = [];
    let paginationTagMarkup;
    pageCount.forEach((item) => {
      if (item === meta.pagination.page) {
        paginationTagMarkup = `
          <span data-value="${item}" class="active wow heartBeat delay1">
            ${item}
          </span>
        `;
      } else {
        paginationTagMarkup = `
          <span data-value="${item}">
            ${item}
          </span>
        `;
      }
      paginationNav.push(paginationTagMarkup);
    });

    let blockPaginationMarkup = `
      <div class="col-lg-8 col-xl-6">
        <div class="row align-items-center">
          <div class="col-2">
            <figure>
              <span class="link-icon-arrow" data-control="prev">
                <img src="${SETTINGS.baseUrl}/images/icons/arrow-left-custom.svg" alt="Icona arrow left" class="left">
              </span>
            </figure>
          </div>
          <div class="col-8">
            <nav class="pagination-custom pagination-nav text-center"></nav>
          </div>
          <div class="col-2">
            <figure class="text-right">
              <span class="link-icon-arrow" data-control="next">
                <img src="${SETTINGS.baseUrl}/images/icons/arrow-right-custom.svg" alt="Icona arrow right" class="right">
              </span>
            </figure>
          </div>
        </div>
      </div>
    `;
    $(`.${typologyName} .list-properties .block-pagination`).html(blockPaginationMarkup);
    $(`.${typologyName} .list-properties .pagination-nav`).html(paginationNav);
    $(`.${typologyName} .list-properties .block-pagination`).show();
    $(`.${typologyName} .list-properties .pagination-nav`).show();

    $(`.${typologyName} .list-properties .pagination-nav span`).on('click', (e) => {
      const page = $(e.currentTarget).data('value');
      history.pushState({}, '', `?page=${page}`);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (typologyName === 'rent') {
        PAGES.getRentProperties(page, (sessionStorage.getItem('rent_category_filter') || 0));
      } else if (typologyName === 'sale') {
        PAGES.getSaleProperties(page, (sessionStorage.getItem('sale_category_filter') || 0));
      } else if (typologyName === 'search') {
        const formData = JSON.parse(sessionStorage.getItem('form_data_search'));
        SEARCH.search(formData);
      }
    });
  },
  noResult(typologyName) {
    let blockTextNotFoundMarkup = `
        <h2 class="separate-large-b animated shake">Nessun immobile trovato...</h2>
    `;
    $(`.${typologyName} .list-properties .cards-properties`).html(blockTextNotFoundMarkup);
    $(`.${typologyName} .list-properties .block-pagination`).hide();
    $(`.${typologyName} .list-properties .pagination-nav`).hide();
  },
  countNumber(typologyName, totalPages) {
    $(`.${typologyName} .hero .count-num`).html(totalPages);
    UTILS.countNumber();
  }
}

export { LISTPROPIERTIES };
