import $ from 'jquery';
import Swiper from 'swiper';

import { SETTINGS } from '../commons/settings';

const SWIPERS = {
  sliderFeaturedHomeProperties(properties) {
    let sliderProperties = [];
    properties.forEach((item) => {
      let sliderMarkup = `
        <div class="swiper-slide item-property">
          <bdi class="code-rif w-bold">Rif. ${item.attributes.reference}</bdi>
          <figure class="img-property" style="background-image: url(${SETTINGS.baseUrlBackend}${item.attributes.cover.data.attributes.url});"></figure>
          <hgroup class="caption">
              <h4 class="w-bold cl-institutional">${item.attributes.typology.data.attributes.name}</h4>
              <h5 class="mt-1 w-bold">${item.attributes.category.data.attributes.name}</h5>
              <h6 class="mt-1">
                <span class="zone">${item.attributes.place}</span>
                <span class="separator">/</span>
                <span class="city">${item.attributes.city.data.attributes.name}</span>
              </h6>
              <a href="${SETTINGS.baseUrl}/dettaglio-immobile?id=${item.id}" class="read-more">
                  read more
               </a>
            </hgroup>
        </div>
      `;
      sliderProperties.push(sliderMarkup);
    });
    $('.swiper-slider-featured-home .swiper-wrapper').html(sliderProperties);
    setTimeout(() => {
      new Swiper('.swiper-slider-featured-home', {
        slidesPerView: 1,
        spaceBetween: 90,
        pagination: {
          el: '.slider-featured-home .swiper-pagination',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.slider-featured-home .swiper-next',
          prevEl: '.slider-featured-home .swiper-prev',
        },
      });
    }, 500);
  },
  sliderCity(cities) {
    let sliderCities = [];
    cities.forEach((item) => {
      let sliderMarkup = `
        <div class="swiper-slide">
          <figure class="img-slide" style="background-image: url(${SETTINGS.baseUrlBackend}${item.attributes.image.data.attributes.url});"></figure>
          <hgroup class="caption">
            <h5 class="w-bold">${item.attributes.name}</h5>
          </hgroup>
        </div>
      `;
      sliderCities.push(sliderMarkup);
    });
    $('.swiper-slider-city .swiper-wrapper').html(sliderCities);
    setTimeout(() => {
      new Swiper('.swiper-slider-city', {
        slidesPerView: 3,
        freeMode: true,
        spaceBetween: 30,
        pagination: {
          el: '.slider-city .swiper-pagination',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.slider-city .swiper-next',
          prevEl: '.slider-city .swiper-prev',
        },
        breakpoints: {
          576: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          991: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
        }
      });
    }, 500);
  },
  sliderSingleProperty(properties) {
    let sliderProperties = [];
    properties.forEach((item) => {
      let sliderMarkup = `
        <div class="swiper-slide item-property">
          <figure class="img-property" style="background-image: url(${SETTINGS.baseUrlBackend}${item.attributes.url});"></figure>
        </div>
      `;
      sliderProperties.push(sliderMarkup);
    });
    $('.swiper-slider-single-property .swiper-wrapper').html(sliderProperties);
    setTimeout(() => {
      new Swiper('.swiper-slider-single-property', {
        slidesPerView: 1,
        freeMode: true,
        spaceBetween: 15,
        pagination: {
          el: '.slider-single-property .swiper-pagination',
          type: 'fraction',
        },
        navigation: {
          nextEl: '.slider-single-property .swiper-next',
          prevEl: '.slider-single-property .swiper-prev',
        },
      });
    }, 500);
  },
  sliderRelatedPlaceProperty(properties) {
    let sliderProperties = [];
    properties.forEach((item) => {
      let sliderMarkup = `
        <div class="swiper-slide item-property">
          <bdi class="code-rif w-bold">Rif. ${item.attributes.reference}</bdi>
          <figure class="img-property" style="background-image: url(${SETTINGS.baseUrlBackend}${item.attributes.cover.data.attributes.url});"></figure>
          <hgroup class="caption">
            <h4 class="w-bold cl-institutional">${item.attributes.typology.data.attributes.name}</h4>
            <h5 class="mt-1 w-bold">${item.attributes.category.data.attributes.name}</h5>
            <h6 class="mt-1 small">
              <span class="zone">${item.attributes.place}</span>
              <span class="separator">/</span>
              <span class="city">${item.attributes.city.data.attributes.name}</span>
            </h6>
            <a href="${SETTINGS.baseUrl}/dettaglio-immobile?id=${item.id}" class="read-more">
              read more
            </a>
          </hgroup>
        </div>
      `;
      sliderProperties.push(sliderMarkup);
    });
    $('.swiper-slider-related-place-property .swiper-wrapper').html(sliderProperties);
    $('.related-place-property').css('display', 'block');
    setTimeout(() => {
      new Swiper('.swiper-slider-related-place-property', {
        slidesPerView: 3,
        spaceBetween: 90,
        navigation: {
          nextEl: '.related-place-property .swiper-next',
          prevEl: '.related-place-property .swiper-prev',
        },
        breakpoints: {
          576: {
            slidesPerView: 1,
          },
          1200: {
            slidesPerView: 2,
          },
        }
      });
    }, 500);
  },
  sliderFeaturedProperties(properties) {
    let sliderProperties = [];
    properties.forEach((item) => {
      let sliderMarkup = `
        <div class="swiper-slide item-property">
          <bdi class="code-rif w-bold">Rif. ${item.attributes.reference}</bdi>
          <figure class="img-property" style="background-image: url(${SETTINGS.baseUrlBackend}${item.attributes.cover.data.attributes.url});"></figure>
          <hgroup class="caption">
            <h4 class="w-bold cl-institutional">${item.attributes.typology.data.attributes.name}</h4>
            <h5 class="mt-1 w-bold">${item.attributes.category.data.attributes.name}</h5>
            <h6 class="mt-1 small">
              <span class="city">${item.attributes.city.data.attributes.name}</span>
            </h6>
            <a href="${SETTINGS.baseUrl}/dettaglio-immobile?id=${item.id}" class="read-more">
                read more
            </a>
          </hgroup>
        </div>
      `;
      sliderProperties.push(sliderMarkup);
    });
    $('.swiper-slider-featured-panel .swiper-wrapper').html(sliderProperties);
    setTimeout(() => {
      new Swiper('.swiper-slider-featured-panel', {
        slidesPerView: 2,
        spaceBetween: 90,
        navigation: {
          nextEl: '.panel-right[data-panel="panel-search"] .swiper-next',
          prevEl: '.panel-right[data-panel="panel-search"] .swiper-prev',
        },
        breakpoints: {
          576: {
            slidesPerView: 1,
          },
          1198: {
            slidesPerView: 2,
          },
          1199: {
            slidesPerView: 1,
          },
          1500: {
            slidesPerView: 1,
          },
        }
      });
    }, 500);
  }
}

export { SWIPERS };
