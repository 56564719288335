import { SETTINGS } from '../commons/settings';

const instanceAxios = axios.create({
  baseURL: SETTINGS.baseUrlBackendApi,
  //timeout: 1000,
  headers: {
    'Authorization': 'Bearer dc3a12c9ef678b185691b263689e65f127ec3c2963f70dd97ac8f63bb77d64aa63941a6bb4b3015b2bac0d99f480a55d4388cf332c6ba8240800f173b3734c8c37df7622cacda1ba1fab03784c5ca83f76e7c02c76b90c7071ec5b5ea1ff5e7551fdf19c5ff28aa35beffe762267558f481f0c9e6d045b8b598d917eaa13181b'
  }
});

const WEB = {
  getApes() {
    return instanceAxios.get('/apes');
  },
  getCategories() {
    return instanceAxios.get('/categories');
  },
  getCities() {
    return instanceAxios.get('/cities?populate[0]=image');
  },
  getTypologies() {
    return instanceAxios.get('/typologies', {
      params: {
        'sort': 'name:asc',
      }
    });
  },
  getRentProperties(page, pageSize, categoryId) {
    let params = {
      'populate[0]': 'category',
      'populate[1]': 'typology',
      'populate[2]': 'city',
      'populate[3]': 'ape',
      'populate[4]': 'cover',
      'filters[typology][id][$eq]': 2,
      'sort': 'publication_date:desc',
      'pagination[page]': page,
      'pagination[pageSize]': pageSize
    }
    if (categoryId > 0) {
      params['filters[category][id][$eq]'] = categoryId;
    }
    return instanceAxios.get('/properties', {
      params: params
    });
  },
  getSaleProperties(page, pageSize, categoryId) {
    let params = {
      'populate[0]': 'category',
      'populate[1]': 'typology',
      'populate[2]': 'city',
      'populate[3]': 'ape',
      'populate[4]': 'cover',
      'filters[typology][id][$eq]': 1,
      'sort': 'publication_date:desc',
      'pagination[page]': page,
      'pagination[pageSize]': pageSize
    }
    if (categoryId > 0) {
      params['filters[category][id][$eq]'] = categoryId;
    }
    return instanceAxios.get('/properties', {
      params: params
    });
  },
  getPropertyById(propertyId) {
    return instanceAxios.get(`/properties/${propertyId}`, {
      params: {
        'populate[0]': 'category',
        'populate[1]': 'typology',
        'populate[2]': 'city',
        'populate[3]': 'ape',
        'populate[4]': 'cover',
        'populate[5]': 'sliders'
      }
    });
  },
  getPropertiesByRelatedPlace(propertyId, typologyId, place) {
    return instanceAxios.get('/properties', {
      params: {
        'populate[0]': 'category',
        'populate[1]': 'typology',
        'populate[2]': 'city',
        'populate[3]': 'cover',
        'filters[id][$ne]': propertyId,
        'filters[typology][id][$eq]': typologyId,
        'filters[place][$eqi]': place,
        'sort': 'publication_date:desc'
      }
    });
  },
  getPropertiesForHome(page, pageSize) {
    return instanceAxios.get('/properties', {
      params: {
        'populate[0]': 'category',
        'populate[1]': 'typology',
        'populate[2]': 'city',
        'populate[3]': 'ape',
        'populate[4]': 'cover',
        'filters[show_homepage][$eq]': true,
        'sort': 'publication_date:desc',
        'pagination[page]': page,
        'pagination[pageSize]': pageSize
        //'randomSort': true
      }
    });
  },
  getPropertiesBySearch(formData, page, pageSize) {
    let params = {
      'populate[0]': 'category',
      'populate[1]': 'typology',
      'populate[2]': 'city',
      'populate[3]': 'ape',
      'populate[4]': 'cover',
      'sort': 'publication_date:desc',
      'pagination[page]': page,
      'pagination[pageSize]': pageSize
    }

    if (formData.typology != '') {
      params['filters[typology][id][$eq]'] = formData.typology;
    }
    if (formData.category != '') {
      params['filters[category][id][$eq]'] = formData.category;
    }
    if (formData.city != '') {
      params['filters[city][id][$eq]'] = formData.city;
    }
    if (formData.zone != '') {
      params['filters[place][$containsi]'] = formData.zone;
    }
    if (formData.reference != '') {
      params['filters[reference][$eq]'] = formData.reference;
    }
    return instanceAxios.get('/properties', {
      params: params
    });
  }
}

export { WEB };
