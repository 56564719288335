import $ from 'jquery';

import { WEB } from '../api/web';
import { SETTINGS } from '../commons/settings';
import { LISTPROPIERTIES } from './list-properties';

const SEARCH = {
  search(formData) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const pageUrlParams = urlParams.get('page');
    const properties = WEB.getPropertiesBySearch(formData, (pageUrlParams || 1), SETTINGS.pageSize);
    properties
      .then((res) => {
        if (res.data.data.length > 0) {
          LISTPROPIERTIES.listProperties(res.data, 'search');
        } else {
          LISTPROPIERTIES.noResult('search');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getTypologiesForSearch(target) {
    const typologies = WEB.getTypologies();
    typologies
      .then((res) => {
        let arrayTypologies = [];
        res.data.data.forEach((item) => {
          let typologyMarkup = `
            <input type="radio" name="typology_${target}" id="${item.attributes.name.toLowerCase()}_${target}" value="${item.id}" data-typologyname="${item.attributes.name.split(' ').slice(-1)}">
            <label class="label-radio" for="${item.attributes.name.toLowerCase()}_${target}">${item.attributes.name.split(' ').slice(-1)}</label>
          `;
          arrayTypologies.push(typologyMarkup);
        });
        $(`form#${target} .radio-typologies`).html(arrayTypologies);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getCategoriesForSearch(target) {
    const categories = WEB.getCategories();
    categories
      .then((res) => {
        let arrayCategories = [];
        let categoryMarkupWrap = `
          <select name="category_${target}" class="cs-select cs-skin-border">
            <option value="0" disabled selected>Categoria</option>
          </select>
        `;
        res.data.data.forEach((item) => {
          let categoryMarkup = `
            <option value="${item.id}">${item.attributes.name}</option>
          `;
          arrayCategories.push(categoryMarkup);
        });
        $(`form#${target} .select-categories`).html(categoryMarkupWrap);
        $(`form#${target} .select-categories select`).append(arrayCategories);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getCitiesForSearch(target) {
    const cities = WEB.getCities();
    cities
      .then((res) => {
        let arrayCities = [];
        let citiesMarkupWrap = `
          <select name="city_${target}" class="cs-select cs-skin-border">
            <option value="0" disabled selected>Città</option>
          </select>
        `;
        res.data.data.forEach((item) => {
          let cityMarkup = `
            <option value="${item.id}">${item.attributes.name}</option>
          `;
          arrayCities.push(cityMarkup);
        });
        $(`form#${target} .select-cities`).html(citiesMarkupWrap);
        $(`form#${target} .select-cities select`).append(arrayCities);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getConditionsForSearch(target) {
    const conditions = [
      { value: 'da_ristrutturare', name: 'Da ristrutturare' },
      { value: 'ristrutturato', name: 'Ristrutturato' },
      { value: 'nuova_costruzione', name: 'Nuova costruzione' }
    ];
    let arrayConditions = [];
    let conditionsMarkupWrap = `
      <select name="condition_${target}" class="cs-select cs-skin-border">
        <option value="0" disabled selected>Condizioni immobile</option>
      </select>
    `;
    conditions.forEach((item) => {
      let conditionMarkup = `
        <option value="${item.value}">${item.name}</option>
      `;
      arrayConditions.push(conditionMarkup);
    });
    $(`form#${target} .select-conditions`).html(conditionsMarkupWrap);
    $(`form#${target} .select-conditions select`).append(arrayConditions);
  }
}

export { SEARCH };
