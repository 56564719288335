import $ from 'jquery';

import { MAIL } from '../utils/mail';
import { WEB } from '../api/web';
import { SWIPERS } from './swipers';
import { SEARCH } from './search';
import { SETTINGS } from '../commons/settings';

import '../libs/selectFx';

const PANELS = {
  init() {
    this.panels()
  },
  panels() {
    $('.open-panel').on('click', (e) => {
      e.preventDefault();
      let target = $(e.currentTarget).data('open-panel');
      $('body').append('<div class="overlay"></div>');

      $(`[data-panel="${target}"]`).addClass('is-open');
      $('.anim').addClass('animated fadeIn delay1');

      switch (target) {
        case 'panel-search':
          SEARCH.getTypologiesForSearch('panel_search');
          SEARCH.getCategoriesForSearch('panel_search');
          SEARCH.getCitiesForSearch('panel_search');
          setTimeout(() => {
            [].slice.call(document.querySelectorAll('form#panel_search select.cs-select')).forEach((el) => {
              new SelectFx(el);
            });
          }, 500);

          $('form#panel_search button[type="submit"]').on('click', (e) => {
            let formSearchPanel = $(e.currentTarget).closest('form');

            formSearchPanel.validate({
              lang: 'it',
              rules: {
                zone_panel_search: {
                  required: false
                },
                rif_panel_search: {
                  required: false,
                  number: true
                },
              },
              messages: {
                rif_panel_search: 'Inserisci un codice valido'
              },
              errorPlacement: (error, element) => {
                error.insertBefore(element);
              },
              errorElement: 'span',
              submitHandler: (formSearchPanel) => {
                const formData = {
                  typology: $(formSearchPanel).find('input[name="typology_panel_search"]:checked').val() || '',
                  category: $(formSearchPanel).find('select[name="category_panel_search"]').parent().find('.cs-options .cs-selected').data('value') || '',
                  city: $(formSearchPanel).find('select[name="city_panel_search"]').parent().find('.cs-options .cs-selected').data('value') || '',
                  zone: $(formSearchPanel).find('input[name="zone_panel_search"]').val(),
                  reference: $(formSearchPanel).find('input[name="rif_panel_search"]').val()
                }
                sessionStorage.setItem('form_data_search', JSON.stringify(formData));
                window.location.href = `${SETTINGS.baseUrl}/ricerca`;
              }
            });
          });
          this.getPropertiesFeatured();
          break;
        case 'panel-contacts':
          $('aside.panel-right button[type="submit"]').on('click', () => {
            let formEmail = $(`aside[data-panel="${target}"] form.send`);

            formEmail.validate({
              lang: 'it',
              rules: {
                fullname: {
                  required: true,
                  minlength: 3
                },
                email: {
                  required: true,
                  email: true
                },
                telephone: {
                  required: true,
                  number: true
                },
                city: {
                  required: true,
                  minlength: 3
                },
                object: {
                  required: true,
                  minlength: 3
                },
                message: {
                  required: true,
                  minlength: 3
                }
              },
              errorPlacement: (error, element) => {
                error.insertAfter(element);
              },
              errorElement: 'span',
              submitHandler: (formEmail) => {
                const formData = {
                  _target: target,
                  fullname: $(formEmail).find('input[name="fullname"]').val(),
                  email: $(formEmail).find('input[name="email"]').val(),
                  telephone: $(formEmail).find('input[name="telephone"]').val(),
                  city: $(formEmail).find('input[name="city"]').val(),
                  object: $(formEmail).find('input[name="object"]').val(),
                  message: $(formEmail).find('input[name="message"]').val()
                }
                // show spinner button
                $(formEmail).find('button[type="submit"]').html('<i class="spin" data-feather="loader"></i>');
                setTimeout(() => {
                  MAIL.sendMail(formEmail, formData, target, `[Rihiesta di contatto] - ${formData.object}`);
                }, 1000);
              }
            });
          });
          break;
        case 'panel-request-data':
          SEARCH.getTypologiesForSearch('request_data');
          SEARCH.getCategoriesForSearch('request_data');
          SEARCH.getConditionsForSearch('request_data');
          setTimeout(() => {
            [].slice.call(document.querySelectorAll('form#request_data select.cs-select')).forEach((el) => {
              new SelectFx(el);
            });
          }, 500);

          $('aside.panel-right button[type="submit"]').on('click', () => {
            let formEmail = $(`aside[data-panel="${target}"] form.send`);
            $.validator.addMethod("eurformat", function (value, element) {
              return this.optional(element) || /^\d{0,4}(\.\d{0,3})?$/.test(value);
            }, 'Inserisci un formato con migliaia es: 100.000');
            formEmail.validate({
              lang: 'it',
              rules: {
                zone: {
                  required: true,
                  minlength: 3
                },
                city: {
                  required: true,
                  minlength: 3
                },
                meters: {
                  required: true,
                },
                price: {
                  eurformat: true
                },
                fullname: {
                  required: true,
                  minlength: 3
                },
                email: {
                  required: true,
                  email: true
                },
                telephone: {
                  required: true,
                  number: true
                },
                message: {
                  required: true,
                  minlength: 3
                }
              },
              errorPlacement: (error, element) => {
                error.insertAfter(element);
              },
              errorElement: 'span',
              submitHandler: (formEmail) => {
                const formData = {
                  _target: target,
                  typology: $(formEmail).find('input[name="typology_request_data"]:checked').data('typologyname'),
                  category: $(formEmail).find('select[name="category_request_data"]').parent().find('.cs-options .cs-selected span').text(),
                  condition: $(formEmail).find('select[name="condition_request_data"]').parent().find('.cs-options .cs-selected').data('value'),
                  zone: $(formEmail).find('input[name="zone"]').val(),
                  city: $(formEmail).find('input[name="city"]').val(),
                  meters: $(formEmail).find('input[name="meters"]').val(),
                  price: $(formEmail).find('input[name="price"]').val(),
                  note: $(formEmail).find('input[name="note"]').val(),
                  fullname: $(formEmail).find('input[name="fullname"]').val(),
                  email: $(formEmail).find('input[name="email"]').val(),
                  telephone: $(formEmail).find('input[name="telephone"]').val()
                }
                // show spinner button
                $(formEmail).find('button[type="submit"]').html('<i class="spin" data-feather="loader"></i>');
                setTimeout(() => {
                  MAIL.sendMail(formEmail, formData, target, `[Rihiesta nuovo immobile] - ${formData.typology}`);
                }, 1000);
              }
            });
          });
          break;
        case 'panel-info-property':
          $('aside.panel-right button[type="submit"]').on('click', () => {
            let formEmail = $(`aside[data-panel="${target}"] form.send`);

            formEmail.validate({
              lang: 'it',
              rules: {
                fullname: {
                  required: true,
                  minlength: 3
                },
                email: {
                  required: true,
                  email: true
                },
                telephone: {
                  required: true,
                  number: true
                },
                city: {
                  required: true,
                  minlength: 3
                },
                message: {
                  required: true,
                  minlength: 3
                }
              },
              errorPlacement: (error, element) => {
                error.insertAfter(element);
              },
              errorElement: 'span',
              submitHandler: (formEmail) => {
                const formData = {
                  _target: target,
                  fullname: $(formEmail).find('input[name="fullname"]').val(),
                  email: $(formEmail).find('input[name="email"]').val(),
                  telephone: $(formEmail).find('input[name="telephone"]').val(),
                  city: $(formEmail).find('input[name="city"]').val(),
                  object: $(formEmail).parent().find('.custom-object .reference').text(),
                  message: $(formEmail).find('input[name="message"]').val()
                }
                // show spinner button
                $(formEmail).find('button[type="submit"]').html('<i class="spin" data-feather="loader"></i>');
                setTimeout(() => {
                  MAIL.sendMail(formEmail, formData, target, `[Rihiesta informazione immobile] - ${formData.object}`);
                }, 1000);
              }
            });
          });
          break;
      }
      $(`[data-panel="${target}"] .close-panel`).on('click', () => {
        $('.anim').removeClass('fadeIn delay1').addClass('fadeOut');

        setTimeout(() => {
          $(`[data-panel="${target}"]`).removeClass('is-open')
        }, 500);

        setTimeout(() => {
          $('.overlay').remove();
          $('.anim').removeClass('animated fadeOut');
        }, 1300);
      });
    })
  },
  getPropertiesFeatured() {
    const propertiesEvidence = WEB.getPropertiesForHome(1, 8);
    propertiesEvidence
      .then((res) => {
        // Swipers
        SWIPERS.sliderFeaturedProperties(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
  },
}

export { PANELS };
