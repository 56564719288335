import $ from 'jquery';

import { SETTINGS } from '../commons/settings';
import { SEARCH } from '../components/search';
import { UTILS } from '../utils/utils';
import { WEB } from '../api/web';
import { SWIPERS } from '../components/swipers';
import { LISTPROPIERTIES } from '../components/list-properties';
import { SINGLEPROPERTY } from '../components/single-property';

import '../libs/selectFx';

const PAGES = {
  currentPage() {
    const currentPage = $('body').attr('data-page');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const pageUrlParams = urlParams.get('page');
    const idUrlParams = urlParams.get('id');
    switch (currentPage) {
      case 'home':
        // Splash
        setTimeout(() => {
          $('.splash .spinner').removeClass('fadeIn delay4').addClass('fadeOut');
          $('.splash h5').removeClass('fadeInUp delay5').addClass('fadeOutDown');
          $('.splash h3').removeClass('fadeInUp delay4').addClass('fadeOutDown delay');
          $('.splash figure.trademark img').removeClass('fadeIn delay2').addClass('fadeOut delay');
        }, 3500);
        setTimeout(() => {
          $('.splash').css({
            'opacity': 0,
            'pointerEvents': 'none'
          });
        }, 4500);

        SEARCH.getTypologiesForSearch('search');
        SEARCH.getCategoriesForSearch('search');
        SEARCH.getCitiesForSearch('search');
        setTimeout(() => {
          [].slice.call(document.querySelectorAll('select.cs-select')).forEach((el) => {
            new SelectFx(el);
          });
        }, 500);

        this.getPropertiesForHome();

        $('form#search button[type="submit"]').on('click', (e) => {
          let formSearch = $(e.currentTarget).closest('form');

          formSearch.validate({
            lang: 'it',
            rules: {
              zone_search: {
                required: false
              },
              rif_search: {
                required: false,
                number: true
              },
            },
            messages: {
              rif_search: 'Inserisci un codice valido'
            },
            errorPlacement: (error, element) => {
              error.insertBefore(element);
            },
            errorElement : 'span',
            submitHandler: (formSearch) => {
              const formData = {
                typology: $(formSearch).find('input[name="typology_search"]:checked').val() || '',
                category: $(formSearch).find('select[name="category_search"]').parent().find('.cs-options .cs-selected').data('value') || '',
                city: $(formSearch).find('select[name="city_search"]').parent().find('.cs-options .cs-selected').data('value') || '',
                zone: $(formSearch).find('input[name="zone_search"]').val(),
                reference: $(formSearch).find('input[name="rif_search"]').val()
              }
              sessionStorage.setItem('form_data_search', JSON.stringify(formData));
              window.location.href = `${SETTINGS.baseUrl}/ricerca`;
            }
          });
        });
        break;
      case 'agency':
        UTILS.countNumber();
        this.getCities();
        break;
      case 'rent':
        this.getCategoriesForFilter();
        setTimeout(() => {
          $('form#filter-properties input[name="category"]').on('change', (e) => {
            $(e.currentTarget).prop('checked', true);
            $('form#filter-properties span.reset').show();
            sessionStorage.setItem('rent_category_filter', $(e.currentTarget).val());
            this.filtering(pageUrlParams, 'Immobile in affitto');
          });
        }, 300);
        if (sessionStorage.getItem('rent_category_filter')) {
          const categoryIdChecked = sessionStorage.getItem('rent_category_filter');
          setTimeout(() => {
            $(`form#filter-properties input[type="radio"][value="${categoryIdChecked}"]`).prop('checked', true);
            $('form#filter-properties span.reset').show();
            this.filtering(pageUrlParams, 'Immobile in affitto');
            this.getRentProperties(pageUrlParams, categoryIdChecked);
          }, 500);
        } else {
          this.getRentProperties(pageUrlParams, 0);
        }
        break;
      case 'sale':
        this.getCategoriesForFilter();
        setTimeout(() => {
          $('form#filter-properties input[name="category"]').on('change', (e) => {
            $(e.currentTarget).prop('checked', true);
            $('form#filter-properties span.reset').show();
            sessionStorage.setItem('sale_category_filter', $(e.currentTarget).val());
            this.filtering(pageUrlParams, 'Immobile in vendita');
          });
        }, 300);
        if (sessionStorage.getItem('sale_category_filter')) {
          const categoryIdChecked = sessionStorage.getItem('sale_category_filter');
          setTimeout(() => {
            $(`form#filter-properties input[type="radio"][value="${categoryIdChecked}"]`).prop('checked', true);
            $('form#filter-properties span.reset').show();
            this.filtering(pageUrlParams, 'Immobile in vendita');
            this.getSaleProperties(pageUrlParams, categoryIdChecked);
          }, 500);
        } else {
          this.getSaleProperties(pageUrlParams, 0);
        }
        break;
      case 'single-property':
        this.getPropertyById(idUrlParams);
        break;
      case 'search':
        if (sessionStorage.getItem('form_data_search')) {
          const formData = JSON.parse(sessionStorage.getItem('form_data_search'));
          SEARCH.search(formData);
        }
        break;
    }
  },
  filtering(pageUrlParams, typologyName) {
    $('form#filter-properties span.link-reset').on('click', (e) => {
      $(e.currentTarget).hide();
      $('form#filter-properties input[type="radio"]').prop('checked', false);
      $('form#filter-properties input#allfilter').prop('checked', true);
      $('form#filter-properties button[type="submit"]').click();
      if (typologyName === 'Immobile in affitto') {
        sessionStorage.removeItem('rent_category_filter');
      } else {
        sessionStorage.removeItem('sale_category_filter');
      }
      history.pushState({}, '', '?page=1');
    });

    $('form#filter-properties button[type="submit"]').on('click', (e) => {
      e.preventDefault();
      let categoryId = $('form#filter-properties input[name="category"]:checked').val();
      if (typologyName === 'Immobile in affitto') {
        this.getRentProperties(pageUrlParams, categoryId);
      } else {
        this.getSaleProperties(pageUrlParams, categoryId);
      }
    });
  },
  getRentProperties(pageUrlParams, categoryId) {
    const rentProperties = WEB.getRentProperties((pageUrlParams || 1), SETTINGS.pageSize, categoryId);
    rentProperties
      .then((res) => {
        if (res.data.data.length > 0) {
          LISTPROPIERTIES.listProperties(res.data, 'rent');
        } else {
          LISTPROPIERTIES.noResult('rent');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getSaleProperties(pageUrlParams, categoryId) {
    const saleProperties = WEB.getSaleProperties((pageUrlParams || 1), SETTINGS.pageSize, categoryId);
    saleProperties
      .then((res) => {
        if (res.data.data.length > 0) {
          LISTPROPIERTIES.listProperties(res.data, 'sale');
        } else {
          LISTPROPIERTIES.noResult('sale');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getPropertiesForHome() {
    const propertiesHome = WEB.getPropertiesForHome(1, 8);
    propertiesHome
      .then((res) => {
        // Swipers
        SWIPERS.sliderFeaturedHomeProperties(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
  },
  getPropertyById(idUrlParams) {
    const property = WEB.getPropertyById(idUrlParams);
    property
      .then((res) => {
        SINGLEPROPERTY.singleProperty(res.data.data);
      })
      .catch((err) => {
        window.location.replace(`${SETTINGS.baseUrl}/not-found`);
      });
  },
  getCities() {
    const cites = WEB.getCities();
    cites
      .then((res) => {
        // Swipers
        SWIPERS.sliderCity(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getCategoriesForFilter() {
    const categories = WEB.getCategories();
    categories
      .then((res) => {
        let arrayCategories = [];
        res.data.data.forEach((item) => {
          let categoryMarkup = `
            <div class="form-group radio-button checked-institutional mr-3">
              <input type="radio" name="category" id="${item.attributes.name.toLowerCase()}" value="${item.id}">
              <label class="label-radio list" for="${item.attributes.name.toLowerCase()}">${item.attributes.name}</label>
            </div>
          `;
          arrayCategories.push(categoryMarkup);
        });
        $(`form#filter-properties .block-categories`).html(arrayCategories);
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

export { PAGES };
