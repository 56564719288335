const { jsPDF } = window.jspdf

import { SETTINGS } from '../commons/settings';

const PDF = {
  createPDF(property) {
    const doc = new jsPDF();

    doc.addImage(`${SETTINGS.baseUrl}/images/marchio-dsimmobiliare.png`, "PNG", 15, 15, 48, 8);
    doc.addImage(`${SETTINGS.baseUrlBackend}${property.attributes.cover.data.attributes.url}`, "JPG", 15, 35, 180, 120);

    doc.setFontSize(12);
    doc.text(`${property.attributes.reference} / Classe Energetica: ${property.attributes.ape.data.attributes.name}`, 15, 165);
    doc.setFontSize(19);
    doc.setTextColor('#DC3833')
    doc.text(`${property.attributes.typology.data.attributes.name}`, 15, 174);
    doc.setFontSize(13);
    doc.setTextColor('#000000');
    doc.text(`${property.attributes.category.data.attributes.name} / ${property.attributes.place} / ${property.attributes.city.data.attributes.name}`, 15, 187);
    doc.setFontSize(12);
    doc.setTextColor('#DC3833');
    if (property.attributes.price) {
      doc.text(`${property.attributes.square_meters} MQ / ${property.attributes.price} €`, 15, 195);
    } else {
      doc.text(`${property.attributes.square_meters} MQ`, 15, 195);
    }
    doc.setFontSize(12);
    doc.setTextColor('#000000');
    doc.text(`${property.attributes.description}`, 15, 210, { maxWidth: 180, lineHeightFactor: 1.6 });
    doc.save(`immobile-rif-${property.attributes.reference}.pdf`);
  }
}

export { PDF };
