import $ from 'jquery';
import { UTILS } from './utils';

const MAIL = {
  sendMail(formEmail, formData, target, subject) {
    let entries = Object.entries(formData);
    let arrayData = entries.map(([key, value]) => {
      switch (key) {
        case 'fullname':
          key = 'Nome e Cognome';
          break;
        case 'email':
          key = 'Email';
          break;
        case 'telephone':
          key = 'Telefono';
          break;
        case 'typology':
          key = 'Tipologia';
          break;
        case 'category':
          key = 'Categoria';
          break;
        case 'condition':
          key = 'Condizioni immobile';
          break;
        case 'zone':
          key = 'Zona e n. civico';
          break;
        case 'city':
          key = 'Città';
          break;
        case 'meters':
          key = 'Metri quadri';
          break;
        case 'price':
          key = 'Prezzo';
          break;
        case 'object':
          key = 'Oggetto';
          break;
        case 'message':
          key = 'Messaggio';
          break;
        case 'note':
          key = 'Note';
          break;
      }
      return `${key}: ${value}`;
    });
    arrayData.shift();

    Email.send({
      SecureToken: 'f25d6f93-dabb-40cb-bce7-ff9ac48a4417',
      To: 'report@federicolaterra.com',
      From: "dev@federicolaterra.com",
      Subject: subject,
      Body: arrayData.join("<br>")
    })
      .then(res => {
        setTimeout(() => {
          $(formEmail)[0].reset();
          $(formEmail).find('button[type="submit"]').html('Invia');
        }, 800);
        setTimeout(() => {
          $(`[data-panel="${target}"] .close-panel`).click();
        }, 1200);
        setTimeout(() => {
          UTILS.toastSq('Modulo inviato con successo!', 'bottom-left');
        }, 1400);
      })
      .catch(err => {
        console.log(err);
        setTimeout(() => {
          $(formEmail)[0].reset();
          $(formEmail).find('button[type="submit"]').html('Invia');
        }, 800);
        setTimeout(() => {
          UTILS.toastSq('Non è stato possibile inviare il modulo. Riprova più tardi!', 'bottom-left');
        }, 1000);
      })
  }
}

export { MAIL };
