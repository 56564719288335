import $ from 'jquery';

import { UTILS } from './utils';

const SCROLLEVENT = {
  init() {
    const currentPage = $('body').attr('data-page');
    UTILS.bottomBarEffect();
    if ($(window).scrollTop() >= (($('#ancor-above').offset().top + 150) - $(window).height())) {
      $('.scroll-down').css('opacity', 0);
    } else {
      $('.scroll-down').css('opacity', 1);
    }

    if (currentPage === "single-property") {
      let affixxOffset = 200,
        affixDom = $('#affixx'),
        affixxElement = affixDom.offset().top - affixxOffset,
        affixxWidth = affixDom.outerWidth(),
        affixxHeight = affixDom.outerHeight(),
        share = $('.share'),
        heightContent = share.offset().top + share.outerHeight() - 170;
      if ($(window).width() >= 767) {
        if ($(window).scrollTop() >= affixxElement && $(window).scrollTop() <= (heightContent - affixxHeight)) {
          $('#affixx').removeClass('ab-bottom').addClass('fixed');
          $('#affixx.fixed').css('width', affixxWidth);
        } else if ($(window).scrollTop() + affixxHeight >= heightContent) {
          let share = $('.share');
          let distance = (share.offset().top - share.height() - 170);
          $('#affixx').removeClass('fixed').addClass('ab-bottom').css('top', distance);
        } else {
          $('#affixx').removeClass('ab-bottom').addClass('fixed');
        }
      }
    }
  }
}

export { SCROLLEVENT };
