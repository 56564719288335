// Main javascript entry point
// Should handle bootstrapping/starting application
'use strict';

/* ---------------------------- Import dependencies NPM ----------------------------------- */
import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import 'jquery-validation';
import 'jquery-validation/dist/localization/messages_it.js';
import 'lightgallery';

import './libs/classie';

/* ---------------------------- Import modules js ----------------------------------- */
import { UTILS } from './utils/utils';
import { PAGES } from './partials/pages';
import { PANELS } from './components/panels';
import { SCROLLEVENT } from './utils/scroll-event';

$(() => {
  PAGES.currentPage();
  UTILS.init();
  PANELS.init();
});

$(window).scroll(() => {
  SCROLLEVENT.init();
});
