import $ from 'jquery';
import FeatherIcons from 'feather-icons';
import Wow from '../../../node_modules/wow.js/dist/wow';
import Clipboard from 'clipboard';

const UTILS = {
  init() {
    this.featherIcons(),
    this.wow(),
    this.tooltip(),
    this.popover(),
    this.scrollTo()
  },
  featherIcons() {
    FeatherIcons.replace();
  },
  wow() {
    new Wow().init();
  },
  tooltip() {
    $('[data-toggle="tooltip"]').tooltip();
  },
  popover() {
    $('[data-toggle="popover"]').popover({
      // trigger: 'focus',
      container: 'body',
      html: true
    });
  },
  scrollTo() {
    $(".scroll").on('click', (e) => {
      e.preventDefault();
      let defaultScrollSpeed = 1500,
        defaultAnchorOffset = 0,
        anchor = $(e.currentTarget).attr('data-scroll'),
        anchorOffset = $(`#${anchor}`).attr('data-scroll-offset'),
        anchorSpeed = parseInt($(`#${anchor}`).attr('data-scroll-speed'));
      if (!anchorOffset) {
        anchorOffset = defaultAnchorOffset;
      }
      if (!anchorSpeed) {
        anchorSpeed = defaultScrollSpeed;
      }
      let target = $(`#${anchor}`).offset().top;
      $('html,body').animate({scrollTop: target - anchorOffset}, anchorSpeed);
    });
  },
  countNumber() {
    $('.count-num').each((index, item) => {
      $(item).prop('Counter',0).animate({
        Counter: $(item).text()
      }, {
        duration: 1500,
        easing: 'swing',
        step: (now) => {
          $(item).text(Math.ceil(now));
        }
      });
    });
  },
  toastSq(message, position) {
    let inAnim = (position === 'top-right' || position === 'bottom-right') ? 'fadeInRight' : 'fadeInLeft';
    let outAnim = (position === 'top-right' || position === 'bottom-right') ? 'fadeOutRight' : 'fadeOutLeft';
    let toastQs = $(`<bdi class="toast-sq ${position} animated ${inAnim}">`);
    $(`<span>${message}</span>`).appendTo(toastQs);
    $('body').append(toastQs);
    setTimeout(() => {
      $(toastQs).addClass(outAnim);
    }, 3000);
    setTimeout(() => {
      $(toastQs).remove();
    }, 4000);
  },
  clipboard() {
    $(document).on('click', '.event-clipboard', (e) => {
      e.preventDefault();
      new Clipboard('.event-clipboard', {
        text: (trigger) => {
          return $(trigger).data('clipboard-text');
        }
      });
      this.toastSq('Link copiato', 'top-right');
    });
  },
  noScrollPage() {
    $('html').on('scroll touchmove mousewheel', (e) => {
      e.stopPropagation();
    })
  },
  bottomBarEffect() {
    let bottomBar = $('.bottom-bar-mobile');
    let bottomBarHeight = bottomBar.outerHeight();
    let lastScroll = 0;
    let scrollTop = $(window).scrollTop();
    if (scrollTop > 120) {
      $(".bottom-bar-mobile").css('bottom', 0);
      if (scrollTop > lastScroll && scrollTop > bottomBarHeight) {
        // scroll up
        $('.bottom-bar-mobile').removeClass('up').addClass('down');
      } else {
        // scroll down
        if (scrollTop + $(window).height() < $(document).height()){
          $('.bottom-bar-mobile').removeClass('down').addClass('up');
        }
      }
      lastScroll = scrollTop;
    } else {
      bottomBar.removeClass('up');
      bottomBar.css('bottom', '-45px');
    }
  }
}

export { UTILS };
